@tailwind base;
@tailwind components;
@tailwind utilities;

@import "chartist/dist/scss/chartist.scss";

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700&family=Outfit:wght@200;300;400;500;600;700&family=Manrope:wght@200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  //   "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-outlined {
  border-width: 2px !important;
}
